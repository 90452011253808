import React from 'react'

const ArrowRight = () => (
    
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 82 86" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 48.3913H62.6127L33.1109 77.8931L40.8891 85.6712L79.78 46.7804C81.9278 44.6325 81.9278 41.1501 79.78 39.0022L40.8891 0.111328L33.1109 7.8895L62.6127 37.3913H0V48.3913Z" fill="#A2A2A2"/>
</svg>
)

export default ArrowRight
