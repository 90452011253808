import React from 'react'

const Logo = () => (
    
<svg viewBox='0 0 1728 490' width="100%"  fill="none" xmlns="http://www.w3.org/2000/svg">

  <path stroke="url(#radial)" fill="url(#radial)" class="letter" fill-rule="evenodd" clip-rule="evenodd" d="M0 202.5C0 91.3981 88.2015.999994 197.174.999994H377.5C486.473.999994 574.951 91.3981 574.951 202.5c0 111.102-88.478 202-197.451 202H197.174C88.2015 404.5 0 313.602 0 202.5Zm197.174-65.612c-35.362 0-63.89 29.559-63.89 65.612s28.528 65.612 63.89 65.612H377.5c35.362 0 64.167-29.559 64.167-65.612s-28.805-65.612-64.167-65.612H197.174ZM574.951 74.6006c0-40.4676 32.177-73.27305 71.869-73.27305h305.77c108.97 0 197.31 90.40285 197.31 201.50445 0 111.102-88.34 201.668-197.31 201.668H646.82c-39.692 0-71.869-33.305-71.869-73.773V74.6006Zm133.285 62.6154v130.896H952.59c35.362 0 64.03-29.227 64.03-65.28s-28.668-65.616-64.03-65.616H708.236Z"/>
  <path stroke="url(#radial)" fill="url(#radial)" class="letter" fill-rule="evenodd" clip-rule="evenodd" d="M1340.3 171.351c23.59-46.836 89.06-47.399 113.42-.978L1576.84 404.5H1728l-156.76-298.229c-75.07-143.0559-276.82-141.3172-349.5 3.011L1072.81 404.5h149.82l117.67-233.149Z" />
</svg>


)

export default Logo
