import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Homepage';
import reportWebVitals from './reportWebVitals';
import { createGlobalStyle } from 'styled-components'

const Breakpoint = "1200px";

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "Repro";
    src:
      url("/fonts/ABCRepro-Regular.woff2") format("woff2");
      url("/fonts/ABCRepro-Regular.woff") format("woff");

  }

  body {
    background: #000000;
    font-size: 20px;
    line-height: 1.4;
    font-family: Repro, system-ui, sans-serif;
    font-feature-settings: 'ss01' on, 'ss08' on;
    letter-spacing: -0.4px;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    color: #A6A6A6;

    @media (max-width: ${Breakpoint}) {
      font-size: 14px;
      letter-spacing: -0.1px;
    }

  },

  a {
    color: white;
    text-decoration: none;
    transition: all .2s;
    &:hover{
      color: green;
      // cursor: ne-resize;
    }
  }

  a::selection {
    background: transparent;
    color: green;
  }
  p::selection {
    background: transparent;
    color: blue;
  }
  div::selection {
    background: transparent;
    color: green;
  }
  span::selection {
    background: transparent;
    color: yellow;
  }


  .circled{
    font-feature-settings: 'ss16' on, 'ss06' on, 'ss01' on, 'ss08' on, 'ss02' on;
  }

  .modal{
    position: absolute;
  }



`

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <GlobalStyle />
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
