import { useState, useEffect } from 'react';
import { styled, keyframes, css} from 'styled-components'
import Logo from './logo.js'
import LogoAlt from './logo_alt.js'

import ArrowLeft from './arrowLeft.js'
import ArrowRight from './arrowRight.js'

import Data from './project.json'
import Lightbox from "yet-another-react-lightbox";
import { Captions, Video } from "yet-another-react-lightbox/plugins";
import CSSProperties from './LightboxStyling.css';

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";


const pulse = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`


const twitch = keyframes`
  0% {
    stroke-width: 132px;
    transform: scale(0.92) translateX(68px) translateY(68px);
  }
  100% {
    stroke-width: 0.1px;
  }
`


const blur = keyframes`
  0% {
    filter: blur(5px);
    // pointer-events: none;
  }
  100% {
    filter: blur(0px);
    pointer-events: none;
  }
`



const Breakpoint = "1200px";


const PageHeader = styled.div`
  // padding: 16px;
  svg::before{
    
  }
  svg{
    width: 100%;
    z-index: 999;
    animation: ${blur} 1s;
    transition: all 1.5s;
    .letter{
      transition: all 1.5s;
      // transition-delay: .2s;
      fill: #A2A2A2;
      stroke: #A2A2A2;
      transform: scale(0.992) translateX(8px) translateY(4px);
      stroke-width: 0px;
      stroke-linejoin: round; 
      animation: ${twitch} 3s;
      filter: box-shadow(2px 2px 10px 0px #FFFFFF);

    }

    // &:hover {
    //   filter: blur(2px);
    //   .letter{
    //     stroke-width: 10px; 
    //     transform: scale(0.987) translateX(12px) translateY(10px);
    //   }
    // }
  
    @media (max-width: ${Breakpoint}) {
      margin-bottom: 0px;
      padding: 0px;
      width: 100%;
      height: auto;
      position: relative;
      mix-blend-mode: normal;

      .letter{
        transition: all 1s;
        transform: scale(1) translateX(1px) translateY(1px);

      }

    }
  }

  .more{
    cursor: pointer;
    // user-select: none;
    // -webkit-user-select: none; 
    // -moz-user-select: none;
    font-size: 20px;
    text-align: right;
    position: fixed;
    top: 8px;
    right: 8px;
    z-index: 999;
    // backdrop-filter: blur(10px);
    // -webkit-backdrop-filter: blur(10px);
    background: #A2A2A2;
    border-radius: 99px;
    padding: 8px 16px;
    color: #000000;
    transition: all .3s;

    &:hover{
      background: white;
    }
    span{
      z-index: 999;
    }
    .circled{
      padding-left: 4px;
    }
  }


  p {
    font-size: 14px;
    margin: 0;
    padding-right: 20px;
  }

  @media (max-width: ${Breakpoint}) {
    grid-template-columns: 1fr; 
    padding: 8px;

    .more{
      display: none;
    }

    p{
      margin-bottom: 16px;
      padding: 0px;
    }

  }
`


const HeaderText = styled.div`
  padding-left: 16px;
  padding-bottom: 32px;

  display: grid;
  grid-template-columns: 2fr 1.5fr 1.5fr 1fr;
  margin-bottom: 20px;
  p {
    font-size: 14px;
    margin: 0;
    padding-right: 20px;
  }

  @media (max-width: ${Breakpoint}) {
    grid-template-columns: 1fr; 
    padding: 128px 16px 0px 16px;

    .more{
      display: none;
    }

    p{
      margin-bottom: 16px;
      padding: 0px;
    }
  }
`


const Footer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.5fr 1fr;
  bottom: 40px;
  @media (max-width: ${Breakpoint}) {
    grid-template-columns: 1fr;
  }
  p {
    padding-left: 16px;
    font-size: 14px;
    letter-spacing: 0px;
    align-self: end;
    line-height: 150%;
  }
  @media (max-width: ${Breakpoint}) {
    p{
      font-size: 20px;
    }
  }
  `


const GridWrap = styled.div`
  padding-left: 16px;
  display: grid;
  grid-template-columns: 2fr 1.5fr 1.5fr 1fr ;
  transition: all .4s;
  pointer-events: ${props => props.expand ? 'none': ''};
  &:hover{
    color: white;
  }

  div{
    margin-top: 12px;
  }

  .index {
    font-size: 14px;
    align-self: center;
  }

  .year{
    font-size: 14px;
    align-self: center;
  }

  @media (max-width: ${Breakpoint}) {
    grid-template-columns: 1fr 1fr ;
    margin-bottom: 12px;
    width: auto;
    pointer-events: none;

    div{
      margin: 0;
    }

    .index{
      display:none;
    }
  }

`

const TableHeader = styled(GridWrap)`
  font-size: 14px;
  padding-bottom: 12px;
  @media (max-width: ${Breakpoint}) {
    display: none;
  }

`

const ImageGrid = styled.div`
  box-sizing: border-box;
  padding-left: 16px; 
  padding-right: 16px; 
  width: auto;
  overflow-y: hidden;
  overflow-x: auto;
  position: relative;
  display: flex;
  transition: all .8s;
  opacity: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 0px;


  ${ props => props.expand && `
    height: 250px;
    opacity: 1;
    transition: all .3s;
    @media (min-width: ${Breakpoint}) {
      img, video{
        filter: brightness(40%) grayscale(100%);
      }
    }
    img, video{
      &:hover{
        filter: brightness(40%) grayscale(100%);
      }
    }
  `}

  @media (max-width: ${Breakpoint}) {
    height: auto;
    opacity: 1;

  }

  &:hover{
    @media (min-width: ${Breakpoint}) {
      img, video{
        filter: brightness(40%) grayscale(100%);
      }
    }
  }

  img, video{
    transition: all .3s;
    height: 250px;
    width: auto;
    margin-right: 16px;
    border-radius: 24px;
    background: #000000;
    &:hover{
      filter: brightness(100%);
    }
    @media (max-width: ${Breakpoint}) {
      width: 90%;
      height: auto;
      opacity: 1;
    }
  }

  &::-webkit-scrollbar{
    display: none;
  }


`

const ProjectsWrapper = styled.div`
  div:nth-child(${props => (props.index)}){

    div:nth-child(1){
      color:white;

    }

    .gallery {
      opacity: 1;
      height: 282px;
      background: #222222;
      margin-top: 12px;
      padding-top: 16px;
      img{
        display: block;
        transition: all .6s;
      }
    }
  }
  margin-bottom: 80px;

`

const ListWrapper = styled.div`

  @media (min-width: ${Breakpoint}) {
    &:hover{
      cursor: pointer;
      color: ${props => props.expand ? 'black': '#555555'};
    }
  }
`

const ProjectItem = styled.div`

  border-top: 1px solid;
  padding-bottom: ${props => props.expand ? '24px': '0px'};
  animation: ${props => props.expand ? css`${pulse} 2s ease-in-out`: "none"};
  border-color: ${props => props.expand ? '#222222': '#000000'};
    &:hover{
      transition: .4s;
    div{
      color: white;
    }
  }

  @media (max-width: ${Breakpoint}) {
    border-color: #222222;
    padding-top: 12px;
    padding-bottom: 24px;
    background: #222222;
  }
`


const BottomSection = styled.section`

  bottom: 8px;
  width: 100%;
  @media (max-width: ${Breakpoint}) {
    position: relative;
    margin-top: 0px;
  }
  .alignEnd{
    justify-self: end;
  }

`


const ControlButton = styled.button`
  background: black;

`

function NextButton(){
  return (
  <ControlButton> <ArrowRight/> </ControlButton>
  )
}

function PrevButton(){
  return (
  <ControlButton> <ArrowLeft/> </ControlButton>
  )
}

function CloseButton(){
  return (
  <ControlButton> Back to Index </ControlButton>
  )
}


function App() {
  
  const [itemIndex, setItemIndex] = useState(0);
  const projects = Data.projects;
  const [large, setLarge] = useState(true);
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [swipe, setSwipe] = useState(0);
  const [index, setIndex] = useState(0)
  const [closeOnPullDown, setCloseOnPullDown] = useState(false);
  const [closeOnBackdropClick, setCloseOnBackdropClick] = useState(true);


  function HandleClick(c, i){
    if (c == i) {
      setItemIndex(0)
    } else {
      setItemIndex(c) 
    }
  }


  function CheckFormat(i){
    if(i.slice(-3)=="mp4"){
      return "video"  
    } else {
      return "image"
    }
  };


  function HandleImageClick(a, i, p){

    setIndex(a.indexOf(i))
    setOpen(true)

    const srcArray = a.map((srcUrl, indice) => ({
      src: srcUrl,
      title: p.project,
      description: p.description[indice],
      width: 3840,
      height: 2160,
      type: CheckFormat(srcUrl),
      sources: [
        {
          src: srcUrl,
          type: "video/mp4"
        }
      ]
    }) );

    setLarge(srcArray)
    console.log(srcArray)
  }

  function expandAction(){   

    setItemIndex(0);
    setExpanded(prevExpanded => !prevExpanded)
  }



  const listProjects = projects.map((project, index) =>

    <ProjectItem className='header' expand={expanded}>
      
      <GridWrap  onClick={() => HandleClick(index + 1, itemIndex)} expand={expanded}>
        {/* <div className="index">{project.id}</div> */}
        <div className="year">{project.year}</div>
        <div>{project.project}</div>
        <div>{project.client}</div>
        <div className="role">{project.role}</div>
      </GridWrap> 

      <ImageGrid className='gallery' expand={expanded}>

        {project.gallery.map((image) => {

        if(image.slice(-4) == ".mp4") {
          return (
          <video playsInline loop className="video" onClick={() => HandleImageClick(project.gallery, image, project)} autoPlay muted >
            <source type="video/mp4" src={image.slice(0, -4) + "xs" + image.slice(-4)} />
          </video>
          )
        }
        else{
          return <img src={image.slice(0, -4) + "xs" + image.slice(-4)} onClick={() => HandleImageClick(project.gallery, image, project)} />
          } 

        })    

        }

      </ImageGrid>

    </ProjectItem>

  );



  return (
    <div className="App">
    
      <PageHeader expand={expanded}>
        <a href="/"> <Logo /> </a>
        <p className="more alignEnd" onClick={()=>expandAction()}>{ expanded ? <div> Less <span className='circled'>—</span> </div> : <div> More <span className='circled'>+</span>  </div> }</p>

      </PageHeader>


      <HeaderText >
        <p></p>
        <p> The office serves as the central design practice of visual designer <a href="https://enniodybeli.com" target="_blank"> <span className='circled'> ID </span>Ennio Dybeli</a>. It was established with the aim of researching, shaping, and actualizing alternative narratives.  </p>
        <p> Steeped in intentionality, the office employs a dialectical approach to design, navigating the space between communities and individuals, form and function, virtual and physical. </p>



      </HeaderText>

      <BottomSection>

      <div>

        <TableHeader>
          {/* <div>Y</div> */}
          <div>Y</div>
          <div>P</div>
          <div>C</div>
          <div>R</div>
        </TableHeader>

        <ListWrapper>
         <ProjectsWrapper index={itemIndex} data={Data}>{listProjects}</ProjectsWrapper>
        </ListWrapper>

      </div>

      <Lightbox 
          open={open}
          close={() => setOpen(false)}
          slides={large}
          index={index}
          animation={{ swipe }}
          controller={{ closeOnPullDown, closeOnBackdropClick }}
          plugins={[Captions, Video]}
          styles={CSSProperties}
          video={
            {
              autoPlay: true,
              loop: true,
              controls: false,
              muted: true,
            }
          }
          render={{
            iconNext: () => <NextButton />,
            iconPrev: () => <PrevButton />,
            iconClose: () => <CloseButton />


        }}  />

        <Footer>
          <p> </p>

          <p> New Projects <br/>
            <a href="mailto:info@oda.services" target="_blank"> <span className='circled'> MAIL </span>info@oda.services </a>
          </p>

          <p> Follow <br/>

              <a href="https://www.instagram.com/oda__services/" target="_blank" >
              <span className='circled'> IG </span>  Instagram
              </a>

              <a href="https://are.na/oda" target="_blank">
                <span className='circled'>  R </span>  Are.na
              </a>
          </p>
          <p> All Rights Reserved  <br/>
          <span className='circled'> ODA </span> © 2023-2024
          </p>


        </Footer>

        </BottomSection>

    </div>
  );
}

export default App;
