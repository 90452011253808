import React from 'react'

const ArrowLeft = () => (
    
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 82 86" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M81.5003 48.3913H18.8876L48.3893 77.8931L40.6112 85.6712L1.72029 46.7804C-0.427596 44.6325 -0.427596 41.1501 1.72029 39.0022L40.6112 0.111328L48.3893 7.8895L18.8876 37.3913H81.5003V48.3913Z" fill="#A2A2A2"/>
</svg>
)

export default ArrowLeft
